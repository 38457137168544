import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
//
import Layout from '~components/layout'
import SEO from '~components/seo'
import PageLayout from '~storybook/layout'

const IndexPage = ({ data, location, pageContext }) => {
  const { language, menus } = pageContext
  const langCode = language.toLowerCase()
  const langMenus = menus[langCode] || {}
  const { drupal } = data
  const { group } = drupal
  const { fieldMetaSiteImage } = group

  return (
    <Layout language={langCode} menus={langMenus}>
      <SEO
        title="404"
        lang={langCode}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
        metatags={[
          {
            __typename: 'MetaValue',
            key: 'robots',
            value: 'noindex, nofollow'
          }
        ]}
      />

      <PageLayout>
        <div className="mt-8 pt-8">
          <h1>
            <FormattedMessage id="404.title" />
          </h1>
          <p>
            <FormattedMessage id="404.message" values={{ path: location.pathname }} />
          </p>
        </div>
      </PageLayout>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($gid: String!, $language: Drupal_LanguageId!) {
    drupal {
      ...FragmentLangs
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
